export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const FETCH_NO_DATA = 'FETCH_NO_DATA';
export const FETCH_NO_NEW_VEHICLE_YEARS_DATA = 'FETCH_NO_NEW_VEHICLE_YEARS_DATA';
export const FETCH_NEW_VEHICLE_YEARS_DATA_SUCCESS = 'FETCH_NEW_VEHICLE_YEARS_DATA_SUCCESS';
export const FETCH_NEW_VEHICLE_YEARS_DATA_ERROR = 'FETCH_NEW_VEHICLE_YEARS_DATA_ERROR';
export const FETCH_NO_NEW_VEHICLE_MAKES_DATA = 'FETCH_NO_NEW_VEHICLE_MAKES_DATA';
export const FETCH_NEW_VEHICLE_MAKES_DATA_SUCCESS = 'FETCH_NEW_VEHICLE_MAKES_DATA_SUCCESS';
export const FETCH_NEW_VEHICLE_MAKES_DATA_ERROR = 'FETCH_NEW_VEHICLE_MAKES_DATA_ERROR';
export const FETCH_NO_NEW_VEHICLE_MODELS_DATA = 'FETCH_NO_NEW_VEHICLE_MODELS_DATA';
export const FETCH_NEW_VEHICLE_MODELS_DATA_SUCCESS = 'FETCH_NEW_VEHICLE_MODELS_DATA_SUCCESS';
export const FETCH_NEW_VEHICLE_MODELS_DATA_ERROR = 'FETCH_NEW_VEHICLE_MODELS_DATA_ERROR';
