import { FETCH_DATA_ERROR, FETCH_DATA_SUCCESS, FETCH_NEW_VEHICLE_MAKES_DATA_ERROR, FETCH_NEW_VEHICLE_MAKES_DATA_SUCCESS, FETCH_NEW_VEHICLE_MODELS_DATA_ERROR, FETCH_NEW_VEHICLE_MODELS_DATA_SUCCESS, FETCH_NEW_VEHICLE_YEARS_DATA_ERROR, FETCH_NEW_VEHICLE_YEARS_DATA_SUCCESS, FETCH_NO_DATA, FETCH_NO_NEW_VEHICLE_MAKES_DATA, FETCH_NO_NEW_VEHICLE_MODELS_DATA, FETCH_NO_NEW_VEHICLE_YEARS_DATA } from '../../actions/types';
const initialState = {
    data: [],
    turnInNewVehicleData: {
        years: [],
        makes: [],
        models: [],
    },
    error: null,
};
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
            };
        case FETCH_NO_DATA:
            return {
                ...state,
                data: null,
            };
        case FETCH_DATA_ERROR:
            return {
                ...state,
                data: null,
                error: action.payload,
            };
        case FETCH_NEW_VEHICLE_YEARS_DATA_SUCCESS:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: action.payload,
                    makes: [],
                    models: [],
                },
                error: null,
            };
        case FETCH_NO_NEW_VEHICLE_YEARS_DATA:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [],
                    makes: [],
                    models: [],
                },
            };
        case FETCH_NEW_VEHICLE_YEARS_DATA_ERROR:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [],
                    makes: [],
                    models: [],
                },
                error: action.payload,
            };
        case FETCH_NEW_VEHICLE_MAKES_DATA_SUCCESS:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [...state.turnInNewVehicleData.years],
                    makes: action.payload,
                    models: [],
                },
                error: null,
            };
        case FETCH_NO_NEW_VEHICLE_MAKES_DATA:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [...state.turnInNewVehicleData.years],
                    makes: [],
                    models: [],
                },
            };
        case FETCH_NEW_VEHICLE_MAKES_DATA_ERROR:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [...state.turnInNewVehicleData.years],
                    makes: [],
                    models: [],
                },
                error: action.payload,
            };
        case FETCH_NEW_VEHICLE_MODELS_DATA_SUCCESS:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [...state.turnInNewVehicleData.years],
                    makes: [...state.turnInNewVehicleData.makes],
                    models: action.payload,
                },
                error: null,
            };
        case FETCH_NO_NEW_VEHICLE_MODELS_DATA:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [...state.turnInNewVehicleData.years],
                    makes: [...state.turnInNewVehicleData.makes],
                    models: [],
                },
            };
        case FETCH_NEW_VEHICLE_MODELS_DATA_ERROR:
            return {
                ...state,
                turnInNewVehicleData: {
                    years: [...state.turnInNewVehicleData.years],
                    makes: [...state.turnInNewVehicleData.makes],
                    models: [],
                },
                error: action.payload,
            };
        default:
            return state;
    }
};
export default dataReducer;
